import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaYoutube } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { ReactComponent as TurboSquidLogo } from './turbosquid-logo.svg';

const Header = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Animation variants
  const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 }
  };

  const imageVariants = {
    hidden: { scale: 0.95, opacity: 0 },
    visible: { 
      scale: 1, 
      opacity: 1,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <motion.header 
      className="bg-[#0B0B0B] w-full shadow-lg font-inter"
      initial="hidden"
      animate="visible"
      variants={headerVariants}
    >
      <div className="max-w-6xl mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          {/* Profile Section */}
          <div className="flex flex-col sm:flex-row items-center gap-6 w-full max-w-3xl">
            {/* Profile Picture */}
            <motion.div 
              className="relative shrink-0"
              variants={itemVariants}
            >
              <motion.div 
                className="absolute -inset-0.5 bg-white/5 rounded-lg blur-sm"
                animate={{ 
                  boxShadow: imageLoaded ? [
                    "0 0 2px rgba(255,255,255,0.2)",
                    "0 0 4px rgba(255,255,255,0.1)",
                    "0 0 2px rgba(255,255,255,0.2)"
                  ] : "none"
                }}
                transition={{ 
                  duration: 3, 
                  repeat: Infinity, 
                  repeatType: "reverse" 
                }}
              ></motion.div>
              <motion.img
                className="relative w-[120px] h-[120px] rounded-lg object-cover grayscale hover:grayscale-0 transition-all duration-500"
                src="https://images.ctfassets.net/srfwb65ha3ql/5g4ASAU2f7EDOlwR8baH5y/6904f787c991f2284253f0dc2a178bd7/pic.jpg"
                alt="Gianluca Gatto Profile"
                loading="eager"
                variants={imageVariants}
                onLoad={() => setImageLoaded(true)}
                whileHover={{ scale: 1.02 }}
              />
            </motion.div>
            
            {/* Name and Description */}
            <motion.div 
              className="flex flex-col items-center sm:items-start justify-center min-h-[120px] gap-3"
              variants={itemVariants}
            >
              <motion.h1 
                className="text-3xl font-bold text-white tracking-tight"
                whileHover={{ scale: 1.02 }}
              >
                <Link to="/" className="hover:text-gray-400 transition-colors duration-300">
                  GIANLUCA GATTO
                </Link>
              </motion.h1>
              <motion.p 
                className="text-sm text-gray-400 max-w-md leading-relaxed text-center sm:text-left"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.8 }}
              >
                I'm Gianluca, a 3D artist using Blender to create bold, immersive art. Let's work together to bring your ideas to life.
              </motion.p>
            </motion.div>
          </div>

          {/* Navigation */}
          <motion.nav 
            className="flex items-center justify-center gap-2"
            variants={itemVariants}
          >
            {/* Contact Button */}
            <Link
              to="/contact"
              aria-label="Contact"
              className={`
                group relative flex h-10 w-10 items-center justify-center 
                overflow-hidden rounded-lg
                transition-all duration-300 hover:w-32
                focus:outline-none focus:ring-2 focus:ring-yellow-300/20
                before:absolute before:inset-0 
                before:bg-gradient-to-r before:from-yellow-200 before:via-yellow-100 before:to-yellow-200
                before:animate-shimmer before:bg-[length:200%_100%]
                after:absolute after:inset-[1px] after:rounded-[7px] 
                after:bg-gradient-to-r after:from-yellow-500 after:to-yellow-600
                after:transition-colors after:duration-300
                hover:after:from-yellow-400 hover:after:to-yellow-500
                [&:not(:hover)]:animate-pulse
                [&:not(:hover)]:shadow-[0_0_15px_rgba(255,215,0,0.5)]
                hover:shadow-[0_0_30px_rgba(255,215,0,0.8)]
                hover:animate-none
              `}
            >
              <motion.div 
                className="relative z-10 flex items-center justify-center w-full h-full transition-all duration-300 group-hover:justify-start group-hover:pl-3"
                whileTap={{ scale: 0.95 }}
              >
                <FaEnvelope className="h-4 w-4 text-yellow-100 transition-transform duration-300 group-hover:scale-90" aria-hidden="true" />
              </motion.div>
              <span className="absolute right-3 z-10 translate-x-full text-xs font-medium text-yellow-100 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
                Contact
              </span>
            </Link>
            
            {/* YouTube Button */}
            <motion.a
              href="https://www.youtube.com/@gianlucagatto1"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
              className="group relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-lg transition-all duration-300 hover:w-32 focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span className="absolute inset-0 bg-red-600 transition-transform transform group-hover:scale-105 duration-300"></span>
              <div className="relative z-10 flex items-center justify-center w-full h-full transition-all duration-300 group-hover:justify-start group-hover:pl-3">
                <FaYoutube className="h-4 w-4 text-white transition-transform duration-300 group-hover:scale-90" aria-hidden="true" />
              </div>
              <span className="absolute right-3 z-10 translate-x-full text-xs font-medium text-white opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
                YouTube
              </span>
            </motion.a>
            
            {/* TurboSquid Button */}
            <motion.a
              href="https://www.turbosquid.com/Search/Artists/GianlucaGatto"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TurboSquid"
              className="group relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-lg transition-all duration-300 hover:w-32 focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span className="absolute inset-0 bg-white transition-transform transform group-hover:scale-105 duration-300"></span>
              <div className="relative z-10 flex items-center justify-center w-full h-full transition-all duration-300 group-hover:justify-start group-hover:pl-3">
                <TurboSquidLogo className="w-6 h-6" aria-hidden="true" />
              </div>
              <span className="absolute right-3 z-10 translate-x-full text-xs font-medium text-black opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
                TurboSquid
              </span>
            </motion.a>
          </motion.nav>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
