import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Contact = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus('sending');
    
    fetch('https://contact-form-service-fu4jw3brrq-uc.a.run.app/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formState),
    })
      .then(() => {
        setFormStatus('success');
        setFormState({
          name: '',
          email: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        setFormStatus('error');
      });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.1
      }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.5 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      className="min-h-screen bg-[#0B0B0B] py-16 px-4 sm:px-6 lg:px-8"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <div className="max-w-4xl mx-auto">
        <motion.div 
          className="text-center mb-10"
          variants={itemVariants}
        >
          <h1 className="text-4xl font-bold text-white mb-3">Get in Touch</h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            I'm always open to discussing new projects, creative ideas or opportunities to be part of your vision.
          </p>
        </motion.div>

        {/* Contact Form */}
        <motion.div 
          className="bg-[#131313] rounded-md p-6 max-w-lg mx-auto"
          variants={itemVariants}
        >
          <h2 className="text-xl font-bold text-white mb-6">Send a Message</h2>
          
          {formStatus === 'success' ? (
            <motion.div 
              className="bg-green-900/30 border border-green-700 text-green-100 p-4 rounded-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <p className="font-medium">Thank you for your message. I'll get back to you soon.</p>
            </motion.div>
          ) : formStatus === 'error' ? (
            <motion.div 
              className="bg-red-900/30 border border-red-700 text-red-100 p-4 rounded-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <p className="font-medium">There was an error sending your message. Please try again later.</p>
            </motion.div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-gray-300 mb-2 text-sm">Name</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={formState.name}
                  onChange={handleChange}
                  required
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-md px-4 py-2 text-white focus:outline-none focus:border-amber-500 transition-all"
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-gray-300 mb-2 text-sm">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  value={formState.email}
                  onChange={handleChange}
                  required
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-md px-4 py-2 text-white focus:outline-none focus:border-amber-500 transition-all"
                />
              </div>
              
              <div>
                <label htmlFor="message" className="block text-gray-300 mb-2 text-sm">Message</label>
                <textarea 
                  id="message" 
                  name="message" 
                  rows="5" 
                  value={formState.message}
                  onChange={handleChange}
                  required
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-md px-4 py-2 text-white focus:outline-none focus:border-amber-500 transition-all resize-none"
                ></textarea>
              </div>
              
              {/* Centered Send Message Button */}
              <div className="flex justify-center">
                <motion.button 
                  type="submit" 
                  className="px-5 py-2 bg-amber-500 text-white font-medium rounded-md transition-all duration-300 hover:bg-amber-600 disabled:opacity-70"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={formStatus === 'sending'}
                >
                  {formStatus === 'sending' ? 'Sending...' : 'Send Message'}
                </motion.button>
              </div>
            </form>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact;