import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { fetchEntries } from '../contentfulClient';
import { PulseLoader } from 'react-spinners';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';

const Artwork = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [artwork, setArtwork] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [fadeOpacity, setFadeOpacity] = useState(1);
  const galleryRef = useRef(null);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    const getArtwork = async () => {
      try {
        setLoading(true);
        const entries = await fetchEntries();
        const foundArtwork = entries.find(entry => entry.fields.slug === id);
        
        if (foundArtwork) {
          setArtwork(foundArtwork);
        } else {
          setError('Artwork not found');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching artwork:', err);
        setError('Failed to load artwork');
        setLoading(false);
      }
    };

    getArtwork();
  }, [id]);

  // Helper function to get images array safely
  const getImages = useCallback(() => {
    if (!artwork) return [];
    
    // If artwork has multiple images in an array
    if (artwork.fields.images && Array.isArray(artwork.fields.images)) {
      return artwork.fields.images;
    }
    
    // If artwork has a single image
    if (artwork.fields.image && artwork.fields.image.fields) {
      return [artwork.fields.image];
    }
    
    return [];
  }, [artwork]);

  const nextImage = useCallback(() => {
    const images = getImages();
    if (!images || images.length <= 1 || imageLoading) return;
    
    setImageLoading(true);
    setFadeOpacity(0);
    
    // Use setTimeout to allow fade out before changing the image
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => {
        return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      });
    }, 200); // Match this with the CSS transition duration
  }, [getImages, imageLoading]);

  const prevImage = useCallback(() => {
    const images = getImages();
    if (!images || images.length <= 1 || imageLoading) return;
    
    setImageLoading(true);
    setFadeOpacity(0);
    
    // Use setTimeout to allow fade out before changing the image
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => {
        return prevIndex === 0 ? images.length - 1 : prevIndex - 1;
      });
    }, 200); // Match this with the CSS transition duration
  }, [getImages, imageLoading]);

  const goToImage = useCallback((index) => {
    if (index === currentImageIndex || imageLoading) return;
    
    setImageLoading(true);
    setFadeOpacity(0);
    
    // Use setTimeout to allow fade out before changing the image
    setTimeout(() => {
      setCurrentImageIndex(index);
    }, 200); // Match this with the CSS transition duration
  }, [currentImageIndex, imageLoading]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        prevImage();
      } else if (e.key === 'ArrowRight') {
        nextImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextImage, prevImage]);

  // Handle image load complete
  const handleImageLoad = () => {
    setImageLoading(false);
    setFadeOpacity(1);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.2,
        delayChildren: 0.1
      }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.5 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <PulseLoader color="#FFD700" size={15} />
      </div>
    );
  }

  if (error || !artwork) {
    return (
      <motion.div 
        className="text-red-500 text-center mt-8 p-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <p>{error || 'Artwork not found'}</p>
        <button 
          onClick={() => navigate('/')}
          className="mt-4 px-4 py-2 bg-gray-800 text-white hover:bg-gray-700 transition-colors"
        >
          Return to Portfolio
        </button>
      </motion.div>
    );
  }

  const images = getImages();
  const hasMultipleImages = images.length > 1;
  const currentImage = images[currentImageIndex]?.fields?.file?.url || '';

  return (
    <motion.div 
      className="min-h-screen bg-[#0B0B0B] p-4 sm:p-6 lg:p-8"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <div className="max-w-6xl mx-auto">
        <motion.button
          onClick={() => navigate('/')}
          className="mb-6 flex items-center text-gray-400 hover:text-white transition-colors"
          whileHover={{ x: -5 }}
          whileTap={{ scale: 0.95 }}
          variants={itemVariants}
        >
          <ArrowLeft className="mr-2" size={16} />
          Back to Portfolio
        </motion.button>

        <div className="bg-[#131313] shadow-lg overflow-hidden">
          <motion.div 
            className="relative w-full"
            variants={itemVariants}
            ref={galleryRef}
          >
            {/* Image Gallery */}
            <div 
              className="relative aspect-video overflow-hidden bg-black"
              ref={imageContainerRef}
            >
              {/* Loading indicator */}
              {imageLoading && (
                <div className="absolute inset-0 flex items-center justify-center z-20 bg-black bg-opacity-40">
                  <PulseLoader color="#FFD700" size={10} />
                </div>
              )}
              
              {/* Single image with fade transition */}
              <img
                src={currentImage}
                alt={`${artwork.fields.title} ${currentImageIndex + 1}`}
                className="w-full h-full object-contain transition-opacity duration-300"
                style={{ opacity: fadeOpacity }}
                onLoad={handleImageLoad}
              />
              
              {/* Navigation Arrows */}
              {hasMultipleImages && (
                <>
                  <button
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 z-30 bg-black bg-opacity-50 w-10 h-10 flex items-center justify-center text-white hover:bg-opacity-70 transition-all rounded-full"
                    onClick={prevImage}
                    aria-label="Previous image"
                    disabled={imageLoading}
                  >
                    <ChevronLeft size={24} />
                  </button>
                  
                  <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 z-30 bg-black bg-opacity-50 w-10 h-10 flex items-center justify-center text-white hover:bg-opacity-70 transition-all rounded-full"
                    onClick={nextImage}
                    aria-label="Next image"
                    disabled={imageLoading}
                  >
                    <ChevronRight size={24} />
                  </button>
                </>
              )}
              
              {/* Image Counter */}
              {hasMultipleImages && (
                <div className="absolute bottom-4 right-4 bg-black bg-opacity-70 text-white px-3 py-1 text-sm z-30 rounded-md">
                  {currentImageIndex + 1} / {images.length}
                </div>
              )}
            </div>
            
            {/* Thumbnails */}
            {hasMultipleImages && (
              <div className="flex overflow-x-auto py-4 px-2 gap-2 bg-[#0F0F0F]">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`relative flex-shrink-0 cursor-pointer overflow-hidden ${
                      currentImageIndex === index ? 'ring-2 ring-yellow-500' : ''
                    } ${imageLoading ? 'pointer-events-none' : ''}`}
                    onClick={() => goToImage(index)}
                  >
                    <img
                      src={image.fields.file.url}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-20 h-20 object-cover"
                    />
                    {currentImageIndex === index && (
                      <div className="absolute inset-0 bg-yellow-500 bg-opacity-20"></div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </motion.div>

          <motion.div 
            className="p-6 sm:p-8"
            variants={itemVariants}
          >
            <motion.h1 
              className="text-3xl font-bold text-white mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              {artwork.fields.title}
            </motion.h1>

            <motion.div 
              className="prose prose-invert max-w-none"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              {artwork.fields.description && documentToReactComponents(artwork.fields.description)}
            </motion.div>

            {artwork.fields.tags && (
              <motion.div 
                className="mt-6 flex flex-wrap gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.6 }}
              >
                {artwork.fields.tags.map((tag, index) => (
                  <motion.span
                    key={index}
                    className="px-3 py-1 bg-gray-800 text-gray-300 text-sm"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.6 + (index * 0.1), duration: 0.4 }}
                    whileHover={{ scale: 1.05, backgroundColor: '#333' }}
                  >
                    {tag}
                  </motion.span>
                ))}
              </motion.div>
            )}
            
            {/* Additional details section */}
            {artwork.fields.details && (
              <motion.div
                className="mt-8 bg-[#1A1A1A] p-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 0.6 }}
              >
                <h2 className="text-xl font-semibold text-white mb-3">Technical Details</h2>
                <div className="text-gray-300">
                  {documentToReactComponents(artwork.fields.details)}
                </div>
              </motion.div>
            )}
            
            {/* External links */}
            {artwork.fields.externalLink && (
              <motion.div
                className="mt-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.6 }}
              >
                <a 
                  href={artwork.fields.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 bg-yellow-600 text-white hover:bg-yellow-700 transition-colors"
                >
                  View on External Platform
                </a>
              </motion.div>
            )}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Artwork;
