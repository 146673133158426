import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaYoutube } from 'react-icons/fa';
import { ReactComponent as TurboSquidLogo } from './turbosquid-logo.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const footerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        delay: 0.2
      }
    }
  };

  const socialLinks = [
    {
      icon: <FaYoutube className="w-5 h-5" />,
      url: 'https://www.youtube.com/@gianlucagatto1',
      label: 'YouTube'
    },
    {
      icon: <TurboSquidLogo className="w-5 h-5" />,
      url: 'https://www.turbosquid.com/Search/Artists/GianlucaGatto',
      label: 'TurboSquid'
    }
  ];

  return (
    <motion.footer 
      className="bg-[#0A0A0A] text-gray-400 py-8 border-t border-gray-800"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={footerVariants}
    >
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <Link to="/" className="text-xl font-bold text-white hover:text-gray-300 transition-colors">
              GIANLUCA GATTO
            </Link>
            <p className="mt-2 text-sm">3D Artist & Digital Creator</p>
          </div>
          
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
            <div className="hidden md:flex space-x-6">
              <Link to="/" className="hover:text-white transition-colors">Portfolio</Link>
              <Link to="/about" className="hover:text-white transition-colors">About</Link>
              <Link to="/contact" className="hover:text-white transition-colors">Contact</Link>
            </div>
            
            <div className="flex justify-center space-x-4">
              {socialLinks.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.label}
                  className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition-colors"
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  whileTap={{ scale: 0.9 }}
                >
                  {link.icon}
                </motion.a>
              ))}
            </div>
          </div>
        </div>
        
        <div className="mt-6 pt-4 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center text-sm">
          <p className="text-center md:text-left">&copy; {currentYear} Gianluca Gatto. All rights reserved.</p>
          
          <div className="mt-4 md:mt-0 flex md:hidden space-x-6">
            <Link to="/" className="hover:text-white transition-colors">Portfolio</Link>
            <Link to="/about" className="hover:text-white transition-colors">About</Link>
            <Link to="/contact" className="hover:text-white transition-colors">Contact</Link>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
