import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    colors: {
      primary: '#0B0B0B',
      secondary: '#131313',
      accent: '#FFD700', // Gold accent color
      text: {
        primary: '#FFFFFF',
        secondary: '#A0A0A0',
      }
    },
    transitions: {
      default: { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] },
      slow: { duration: 0.8, ease: [0.43, 0.13, 0.23, 0.96] },
      fast: { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] },
    }
  });

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export default ThemeContext;
