import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchEntries } from '../contentfulClient';
import { useTheme } from '../ThemeContext';
import { PulseLoader } from 'react-spinners';

const Portfolio = () => {
  const [artworks, setArtworks] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    const getArtworks = async () => {
      try {
        setLoading(true);
        const allArtworks = await fetchEntries();
        setArtworks(allArtworks);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching artworks:', err);
        setError('Failed to load artworks');
        setLoading(false);
      }
    };

    getArtworks();
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      }
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.5 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: theme.transitions.default.ease
      }
    }
  };

  if (error) {
    return (
      <motion.div 
        className="text-red-500 text-center mt-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {error}
      </motion.div>
    );
  }

  return (
    <motion.div 
      className="relative flex items-start justify-center min-h-screen bg-[#0B0B0B] p-4 sm:p-6 lg:p-8"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      {/* Main container */}
      <motion.div 
        className="bg-[#131313] shadow p-6 sm:p-8 w-full max-w-[1200px] z-10 relative"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <PulseLoader color="#FFD700" size={15} />
          </div>
        ) : (
          <motion.div 
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6"
            variants={containerVariants}
          >
            {artworks.length > 0 ? (
              artworks.map((artwork, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  className="group"
                >
                  <Link to={`/artworks/${artwork.fields.slug}`} className="relative block">
                    <div className="relative w-full h-0 pb-[133%] shadow overflow-hidden">
                      <motion.img 
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        src={artwork.fields.image.fields.file.url}
                        alt={artwork.fields.title}
                        loading="lazy"
                        initial={{ opacity: 0, scale: 1.1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        whileHover={{ scale: 1.05 }}
                      />
                      <div 
                        className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex items-end justify-center p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                      >
                        <p 
                          className="text-white text-lg font-medium tracking-wide transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300"
                        >
                          {artwork.fields.title}
                        </p>
                      </div>
                    </div>
                  </Link>
                </motion.div>
              ))
            ) : (
              <motion.p 
                className="text-center text-gray-400 col-span-3"
                variants={itemVariants}
              >
                No artworks available.
              </motion.p>
            )}
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Portfolio;
