import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'framer-motion';
import { useRef } from 'react';

const About = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  
  const isInView1 = useInView(ref1, { once: true, margin: "-100px 0px" });
  const isInView2 = useInView(ref2, { once: true, margin: "-100px 0px" });
  const isInView3 = useInView(ref3, { once: true, margin: "-100px 0px" });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.2,
        delayChildren: 0.1
      }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.5 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <motion.div 
      className="min-h-screen bg-[#0B0B0B] py-12 px-4 sm:px-6 lg:px-8"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          className="text-4xl font-bold text-white mb-8 text-center"
          variants={itemVariants}
        >
          About Me
        </motion.h1>

        <motion.div 
          className="bg-[#131313] rounded-lg shadow-lg p-6 sm:p-8 mb-12"
          variants={itemVariants}
        >
          <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
            <motion.img 
              src="https://images.ctfassets.net/srfwb65ha3ql/5g4ASAU2f7EDOlwR8baH5y/6904f787c991f2284253f0dc2a178bd7/pic.jpg"
              alt="Gianluca Gatto"
              className="w-48 h-48 object-cover rounded-lg shadow-lg"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              whileHover={{ scale: 1.05 }}
            />
            
            <div>
              <motion.h2 
                className="text-2xl font-bold text-white mb-4"
                variants={itemVariants}
              >
                Gianluca Gatto
              </motion.h2>
              
              <motion.p 
                className="text-gray-300 mb-4 leading-relaxed"
                variants={itemVariants}
              >
                I am a passionate 3D artist specializing in creating immersive digital worlds and characters using Blender. With years of experience in the field, I've developed a unique style that blends technical precision with creative vision.
              </motion.p>
              
              <motion.p 
                className="text-gray-300 leading-relaxed"
                variants={itemVariants}
              >
                My work has been featured in various digital art platforms and I've collaborated with clients across different industries to bring their ideas to life. I'm constantly exploring new techniques and pushing the boundaries of what's possible in 3D art.
              </motion.p>
            </div>
          </div>
        </motion.div>

        <div ref={ref1}>
          <motion.div 
            className="bg-[#131313] rounded-lg shadow-lg p-6 sm:p-8 mb-12"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.8 }}
          >
            <motion.h2 
              className="text-2xl font-bold text-white mb-6"
              variants={itemVariants}
            >
              My Approach
            </motion.h2>
            
            <motion.p 
              className="text-gray-300 mb-4 leading-relaxed"
              variants={itemVariants}
            >
              I believe that great 3D art is a balance between technical excellence and creative expression. My process begins with thorough research and conceptualization, followed by meticulous modeling, texturing, and lighting to create visually stunning and emotionally resonant pieces.
            </motion.p>
            
            <motion.p 
              className="text-gray-300 leading-relaxed"
              variants={itemVariants}
            >
              Each project is an opportunity to tell a story and evoke emotion through visual means. Whether I'm creating a character, an environment, or an abstract piece, I focus on creating work that connects with viewers on a deeper level.
            </motion.p>
          </motion.div>
        </div>

        <div ref={ref2}>
          <motion.div 
            className="bg-[#131313] rounded-lg shadow-lg p-6 sm:p-8 mb-12"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.8 }}
          >
            <motion.h2 
              className="text-2xl font-bold text-white mb-6"
              variants={itemVariants}
            >
              Skills & Expertise
            </motion.h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <motion.div 
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
                className="bg-gray-800 p-4 rounded-lg"
              >
                <h3 className="text-xl font-semibold text-white mb-2">3D Modeling</h3>
                <p className="text-gray-300">Expert in creating detailed and optimized 3D models for various applications.</p>
              </motion.div>
              
              <motion.div 
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
                className="bg-gray-800 p-4 rounded-lg"
              >
                <h3 className="text-xl font-semibold text-white mb-2">Texturing</h3>
                <p className="text-gray-300">Skilled in creating realistic and stylized textures that bring models to life.</p>
              </motion.div>
              
              <motion.div 
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
                className="bg-gray-800 p-4 rounded-lg"
              >
                <h3 className="text-xl font-semibold text-white mb-2">Lighting & Rendering</h3>
                <p className="text-gray-300">Proficient in creating atmospheric lighting and high-quality renders.</p>
              </motion.div>
              
              <motion.div 
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
                className="bg-gray-800 p-4 rounded-lg"
              >
                <h3 className="text-xl font-semibold text-white mb-2">Animation</h3>
                <p className="text-gray-300">Experienced in creating fluid and expressive animations for characters and objects.</p>
              </motion.div>
            </div>
          </motion.div>
        </div>

        <div ref={ref3}>
          <motion.div 
            className="bg-[#131313] rounded-lg shadow-lg p-6 sm:p-8"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.8 }}
          >
            <motion.h2 
              className="text-2xl font-bold text-white mb-6"
              variants={itemVariants}
            >
              Let's Create Together
            </motion.h2>
            
            <motion.p 
              className="text-gray-300 mb-6 leading-relaxed"
              variants={itemVariants}
            >
              I'm always open to new collaborations and exciting projects. Whether you have a specific vision in mind or need creative input, I'd love to hear from you and discuss how we can bring your ideas to life.
            </motion.p>
            
            <motion.div 
              className="flex justify-center"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <a 
                href="/contact" 
                className="px-6 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 text-white font-medium rounded-lg hover:from-yellow-400 hover:to-yellow-500 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Get in Touch
              </a>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
